<template>
    <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            :width="isList ? '1000px' : '720px'"
            class="chat-box"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            @close="handleClose">
        <div slot="title"></div>
        <div class="uf hi100">
<!--            left--聊天列表-->
            <div class="uf uf-column" style="width:280px;height: 100%;background-color:#eeeded;" v-if="isList">
                <!--                搜索框-->
                <div class="p-3 " style="border-bottom: 1px solid #ccc;padding-bottom: 0.7rem">
                    <el-input
                            placeholder="请输入专家（医生）姓名"
                            size="small"
                            prefix-icon="el-icon-search"
                            @change="getMessageList(false,true)"
                            v-model="userName">
                    </el-input>
                </div>
<!--                &lt;!&ndash;           tabs&#45;&#45;消息状态&ndash;&gt;-->
<!--                <div class="pl-2 pr-2">-->
<!--                    <el-tabs v-model="activeName" @tab-click="handleClick">-->
<!--                        <el-tab-pane label="进行中" name="conduct"></el-tab-pane>-->
<!--                        <el-tab-pane label="已完成" name="complete"></el-tab-pane>-->
<!--                    </el-tabs>-->
<!--                </div>-->
                <!--                消息列表-->
                <el-scrollbar class="wi100 uf-f1 sys-zd mt-2" id="messageScroll" ref="messageListBox">
                    <div class="uf uf-ac cp wi100" style="padding: 10px;" v-for="(item, index) in expertList" :key="index"
                         :class="isSelect === item.toId ? 'select-style' : ''" @click="expertSwitch(item)">
                        <el-badge :value="item.unreadCount" :max="99" class="item" :hidden="item.unreadCount <= 0">
                            <el-avatar shape="square" style="width: 45px;height: 45px;"
                                       :src="item.sysUserEntity.headPath ? $cons.SERVER_PATH + item.sysUserEntity.headPath : 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'"></el-avatar>
                        </el-badge>
                        <div class="ml-2 uf-f1 lh1">
                            <div class="uf uf-ac uf-pj">
                                <div>{{item.sysUserEntity.name}}</div>
                                <div>{{item.bsRoomMsgEntity.sendTime}}</div>
                            </div>
                            <div class="mt-1 text-hiding" style="max-width: 190px">
                                <span v-if="item.bsRoomMsgEntity.msgType === 'TEXT'">{{item.bsRoomMsgEntity.msgText}}</span>
                                <span v-else>图片</span>
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
            <!--            right--聊天内容-->
            <div class="uf-f1 uf uf-column uf-ac" v-loading="loading">
                <!--                头部标题-->
                <div class="uf uf-pc uf-ac p-2 pr doctor_header">
                    <div class="f18 fwb">{{doctorInfo.name}}</div>
                    <div class="title-close" @click="handleClose">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
<!--                <div style="width: 100%;">-->
<!--                    <el-card shadow="never">-->
<!--                        <el-row>-->
<!--                            <el-col :span="4">-->
<!--                                <img style="width: 80px;height: 80px;" :src="doctorInfo.headPath ? $cons.SERVER_PATH + doctorInfo.headPath : 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'"/>-->
<!--                            </el-col>-->
<!--                            <el-col :span="20">-->
<!--                                {{doctorInfo.doctorDesc}}-->
<!--                            </el-col>-->
<!--                        </el-row>-->
<!--                    </el-card>-->
<!--                </div>-->
                <!--                聊天内容-->
                <el-scrollbar class="uf-f1 wi100 sys-zd" ref="msgBox" id="chatScroll">
                    <div class="p-2" >
                        <div class="uf uf-ac uf-pc mb-2" v-if="topLoading">
                            <i class="el-icon-loading"></i>
                        </div>
                        <div v-for="(item,index) in messageList" :key="index">
                            <!--                    欢迎提示语-->
                            <template v-if="item.role === 'artificial' ">
                                <div class="uf uf-ac uf-pc mb-2" >
                                    <div class="chat-time">{{item.content}}</div>
                                </div>
                            </template>
                            <!--                    聊天时间-->
                            <template v-if="item.time ">
                                <div class="uf uf-ac uf-pc mb-2" v-if="getTimeDiff(item,index)">
                                    <div class="chat-time">{{moment(item.time).format('YYYY年MM月DD日 HH:mm:ss')}}</div>
                                </div>
                            </template>
                            <!--                    专家聊天内容-->
                            <div class="uf uf-ac mb-2" v-if="item.role === 'doctor'">
                                <el-avatar shape="square" style="width: 45px;height: 45px;"
                                           :src="doctorInfo.headPath ? $cons.SERVER_PATH + doctorInfo.headPath : 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'"></el-avatar>
                                <div class="sys-text" v-if="item.type === 'TEXT'">{{item.content}}</div>
                                <div v-else class="" style="margin-left: 12px;">
                                    <el-image
                                            style="max-width: 200px"
                                            fit="scale-down"
                                            :src="$cons.SERVER_PATH + item.content"
                                            :preview-src-list="srcList">
                                    </el-image>
                                </div>
                            </div>
                            <!--                   用户聊天内容-->
                            <div>
                                <div class="uf uf-ac uf-pe mb-2" v-if="item.role === 'user'">
                                    <div class="expert-text" v-if="item.type === 'TEXT'">{{item.content}}</div>
                                    <div v-else class="" style="margin-right: 12px;">
                                        <el-image
                                                style="max-width: 200px"
                                                fit="scale-down"
                                                :src="item.ongoing ? item.content : $cons.SERVER_PATH + item.content"
                                                :preview-src-list="srcList">
                                        </el-image>
                                    </div>
                                    <el-avatar shape="square" style="width: 45px;height: 45px;"
                                               :src="$cons.SERVER_PATH + userHead"></el-avatar>
                                </div>
                            </div>
                            <!--                    结束语-->
                            <!--              <div class="uf uf-ac uf-pc mb-2">-->
                            <!--                <div class="auxiliary-text">问诊结束，如有疑问可再次咨询！</div>-->
                            <!--              </div>-->
                        </div>

                    </div>
                </el-scrollbar>
                <!--                聊天输入-->
                <div style="height: 150px;width:100%;border-top: 1px solid #eeeded" class="uf uf-column">
                    <!--                输入框--title（图片）-->
                    <div class="uf uf-ac uf-pj p-2">
                        <div>
                            <el-upload
                                    class="upload-demo"
                                    :action="$cons.API_SERVER_PATH + $api.QUALITY_DOCTOR.UPLOAD_IMAGE"
                                    :data="uploadData"
                                    :headers="uploadHeaders"
                                    accept=".jpg,.jpeg,.JPG,.png,.PNG"
                                    :on-preview="handlePreview"
                                    :on-exceed="handleExceed"
                                    :on-success="handleSuccess"
                                    :show-file-list="false">
                                <i class="el-icon-picture f20 cp"></i>
                            </el-upload>
                        </div>
                    </div>
                    <!--                    输入框-->
                    <div class="uf-f1">
                        <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="contentText" resize="none"
                                   ref="sendMsg" @keyup.enter.native="sendText('TEXT')"></el-input>
                    </div>
                    <!--                    发送按钮-->
                    <div class="uf uf-pe pr-2 pb-2">
                        <el-button type="primary" size="small" @click="sendText('TEXT')">发送</el-button>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import moment from 'moment'
    import Vue from 'vue'
    let Base64 = require('js-base64').Base64
    export default {
        name: 'chat-box',
        data () {
            return {
                moment,
                // webSocket-链接
                ws: null,
                dialogVisible: false,
                userName: '',
                search: '',
                //  消息类型（进行中，已完成)--默认显示进行中
                activeName: 'conduct',
                //   当前选中的消息
                isSelect: '',
                //   输入消息内容
                contentText: '',
                //   图片列表
                srcList: [],
                doctorInfo: {},
                uploadData: {
                    fromId: '',
                    toId: ''
                },
                messageList: [],
                uploadHeaders: {
                    token: Vue.cookie.get('marketToken')
                },
            //    历史消息记录分页参数
                page: 1,
                limit: 30,
                totalCount: 0,
                totalPage: 0,
            //  滑动触顶加载标记
                topLoading: false,
                loading: false,
                scrollHeight: 0,
                imageUrl: '',
                // 是否显示消息成员列表
                isList: false,
            //  消息成员列表
            //  消息列表分页参数
                pageIndex: 1,
                pageSize: 50,
                expertList: [],
                expertTotalPage: 0,
                messageTime: null
            }
        },
        mounted () {
            this.$nextTick(() => {
                // document.getElementById('messageScroll').addEventListener('scroll', this.messageListScroll, true)
            })
        },
        computed: {
            userId: {
                get () { return this.$store.state.user.id },
                set (val) { this.$store.commit('user/updateId', val) }
            },
            userHead: {
                get () { return this.$store.state.user.userHead },
                set (val) { this.$store.commit('user/updateUserHead', val) }
            }
        },
        methods: {
            //  初始化
            init (item, doctorDesc, isList) {
                this.dialogVisible = true
                this.$nextTick(() => {
                    if (!isList) {
                        this.doctorInfo = this.$deepClone(item)
                        this.doctorInfo.doctorDesc = this.$deepClone(doctorDesc)

                        this.initWebSocket()
                        this.getChatRecord()
                    }
                    if (isList) {
                        this.isList = true
                        this.getMessageList(true)
                    //    获取消息列表
                        // webSocket 链接成功--轮询查询消息成员列表--获取未读消息
                        if (this.messageTime) {
                            clearInterval(this.messageTime)
                            this.messageTime = null
                        }
                        this.messageTime = setInterval(() => {
                            this.getMessageList()
                        },5000)
                    }
                    let that = this
                    document.getElementById('chatScroll').addEventListener('scroll', that.chatListScroll, true)
                })
            },
            // 滚动事件
            chatListScroll () {
                let scrollTop = this.$refs.msgBox.$refs.wrap.scrollTop
                let clientHeight = this.$refs.msgBox.$refs.wrap.clientHeight
                let scrollHeight = this.$refs.msgBox.$refs.wrap.scrollHeight
                // console.log(scrollTop, clientHeight, scrollHeight)
                if (scrollTop + clientHeight === scrollHeight) {
                    // console.log('触底加载')
                }
                if (scrollTop === 0) {
                    if (this.page < this.totalPage) {
                        this.scrollHeight = this.$deepClone(scrollHeight)
                        this.topLoading = true
                        this.page = this.page + 1
                        this.getChatRecord(true)
                    }

                }
            },
            // 聊天列表滚动
            messageListScroll () {
                let scrollTop = this.$refs.messageListBox.$refs.wrap.scrollTop
                let clientHeight = this.$refs.messageListBox.$refs.wrap.clientHeight
                let scrollHeight = this.$refs.messageListBox.$refs.wrap.scrollHeight
                if (scrollTop + clientHeight === scrollHeight) {
                    // console.log('触底加载')
                    if (this.pageIndex < this.expertTotalPage) {
                        this.pageIndex = this.pageIndex + 1
                        this.getMessageList(false, false, true)
                    }
                }
            },
            //  获取当前消息成员列表
            getMessageList (isFirst = false, isSelect = false, isLoading = false) {
                this.dataListLoading = true
                if (isSelect) {
                    this.expertList = []
                }
                this.$http({
                    url: this.$http.adornUrl(this.$api.QUALITY_DOCTOR.MESSAGE_LIST),
                    method: 'GET',
                    params: this.$http.adornParams({
                        page: this.pageIndex,
                        limit: this.pageSize,
                        userName: this.userName
                    })
                }).then(({data}) => {
                    this.dataListLoading = false
                    if (data && data.code === 0) {
                        let page = data.page || {}
                        this.expertList = isLoading ? this.expertList.concat(page.list || []) : page.list || []
                        this.expertTotalPage = page.totalPage || 0
                        // 首次查询--默认查询第一个消息成员的历史消息记录
                        if (this.expertList && this.expertList.length > 0 && isFirst) {
                            this.doctorInfo = this.expertList[0].sysUserEntity || {}
                            this.isSelect = this.expertList[0].toId || ''
                            this.initWebSocket()
                            this.getChatRecord()
                            document.getElementById('messageScroll').addEventListener('scroll', this.messageListScroll, true)
                        }
                    }
                })
            },
            // 消息切换
            expertSwitch (item) {
                this.ws.close()
                this.isSelect = item.toId || ''
                this.doctorInfo = item.sysUserEntity || {}
                this.messageList = []
                this.srcList = []
                this.page = 1
                this.limit = 30
                this.totalPage = 1
                this.initWebSocket()
                this.getChatRecord()
            },
            // 查询聊天记录
            getChatRecord (isLoading = false) {
                this.loading = true
                this.$http({
                    url: this.$http.adornUrl(this.$api.QUALITY_DOCTOR.CHAT_RECORD),
                    method: 'GET',
                    params: this.$http.adornParams({
                        page: this.page,
                        limit: this.limit,
                        fromId: this.userId,
                        toId: this.doctorInfo.userId
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        let page = data.page || {}
                        let list = page.list || []
                        this.totalPage = page.totalPage || 0
                        if (list && list.length > 0) {
                            if (isLoading) {
                                list.forEach(item => {
                                    if (item.fromId === this.userId) {
                                        this.messageList.unshift({
                                            role: 'user',
                                            type: item.msgType,
                                            content: item.msgText,
                                            headPath: '',
                                            time: item.sendTime
                                        })
                                    } else {
                                        this.messageList.unshift({
                                            role: 'doctor',
                                            type: item.msgType,
                                            content: item.msgText,
                                            headPath: '',
                                            time: item.sendTime
                                        })
                                    }
                                    if (item.msgType === 'IMAGE') {
                                        this.srcList.unshift(this.$cons.SERVER_PATH  + item.msgText)
                                    }
                                })
                                this.topLoading = false
                                this.$nextTick(() => {
                                    this.$refs['msgBox'].wrap.scrollTop = this.$refs['msgBox'].wrap.scrollHeight - this.scrollHeight
                                })
                            } else {
                                let messageList = list.reverse()
                                messageList.forEach(item => {
                                    if (item.fromId === this.userId) {
                                        this.messageList.push({
                                            role: 'user',
                                            type: item.msgType,
                                            content: item.msgText,
                                            headPath: '',
                                            time: item.sendTime
                                        })
                                    } else {
                                        this.messageList.push({
                                            role: 'doctor',
                                            type: item.msgType,
                                            content: item.msgText,
                                            headPath: '',
                                            time: item.sendTime
                                        })
                                    }
                                    if (item.msgType === 'IMAGE') {
                                        this.srcList.push(this.$cons.SERVER_PATH  + item.msgText)
                                    }
                                })
                                this.messageList.push({
                                    role: 'artificial',
                                    type: 'WELCOME',
                                    content: '欢迎进入人工诊断服务，请输入您的问题',
                                    headPath: '',
                                    time: ''
                                })
                                this.$nextTick(() => {
                                    setTimeout(() => {
                                        this.scrollBottm()
                                    }, 500)
                                })
                            }
                        }
                    }
                    this.loading = false
                })
            },
            // 进入页面创建websocket连接
            initWebSocket () {
                let _this = this
                // 判断页面有没有存在websocket连接
                if (window.WebSocket) {
                    let socketUrl = ''
                    // let fromId = '0125412'
                    // if (process.env.NODE_ENV === 'development') {
                    // socketUrl = 'ws://127.0.0.1:9081/smes-server'
                  socketUrl = 'ws://console.smartquality.cn/smes-server'
                    // } else {
                        // socketUrl = 'ws://www.chealth.cn/smes-server'
                        // socketUrl = 'ws://www.smartquality.cn/smes-server'
                    // }
                    this.uploadData.fromId = this.userId || ''
                    this.uploadData.toId = this.doctorInfo.userId || ''
                    var url = `${socketUrl}/wsim/${this.userId}/${this.doctorInfo.userId}`
                    let ws = new WebSocket(url)
                    _this.ws = ws
                  _this.ws.onopen = function (e) {
                        // console.log('服务器连接成功: ' + url)
                        if (_this.isList) {
                            setTimeout(() => {
                                _this.getMessageList()
                            }, 1000)
                        }
                        ws.onmessage = function (e) {
                            // 接收服务器返回的数据
                            let data = Base64.decode(e.data)
                            let type = ''
                            let content = data.substring(20)
                            let time = moment(Number(data.substring(0,10) * 1000)).format('YYYY-MM-DD HH:mm:ss')
                            if (data.indexOf('TEXT') !== -1) {
                                type = data.substring(10,14)
                            } else {
                                type = data.substring(10,15)
                            }
                            _this.messageList.push({
                                role: 'doctor',
                                type: type,
                                content: content,
                                headPath: '',
                                time: time
                            })
                            _this.srcList.push(_this.$cons.SERVER_PATH + content)
                            _this.scrollBottm()
                        }
                    }
                    _this.ws.onclose = function (e) {
                    }
                    _this.ws.onerror = function () {
                        // console.log('服务器连接出错: ' + url)
                    }
                }
            },
            // 发送聊天信息
            sendText (type) {
                let _this = this
                _this.$refs['sendMsg'].focus()
                if (!_this.contentText && type === 'TEXT') {
                    this.$message.warning('请输入消息内容')
                    return
                }
                let data = type === 'TEXT' ? `TEXT@@@@@@${_this.contentText}` : `IMAGE@@@@@${_this.headPath}`
                // 调用WebSocket send()发送信息的方法
                // _this.ws.send(JSON.stringify(data))
                let contentText = this.$deepClone(_this.contentText)
                if (_this.ws.readyState === 1) {
                    _this.ws.send(Base64.encode(data))
                    _this.contentText = ''
                    setTimeout(() => {
                        this.messageList.push({
                            role: 'user',
                            type: type,
                            content: type === 'TEXT' ? contentText : this.imageUrl,
                            headPath: '',
                            ongoing: true,
                            time: moment().format('YYYY-MM-DD HH:mm:ss')
                        })
                        _this.scrollBottm()
                    }, 500)
                } else {
                    this.$message.error('当前链接已断开，请关闭弹框重新链接')
                }
            },
            // 滚动条到底部
            scrollBottm () {
                let el = this.$refs['msgBox']
                this.$nextTick(() => {
                    el.wrap.scrollTop = el.wrap.scrollHeight
                })
            },
            // 计算时间差
            getTimeDiff (item, index) {
                let list = this.$deepClone(this.messageList)
                if (index === 0) {
                    return true
                }
                if (index > 0) {
                    if (moment(item.time).diff(moment(list[index - 1].time), 'minute') > 3) {
                        return true
                    } else {
                        return false
                    }
                }
            },
            //   图片上传进行时
            handlePreview () {
            },
            //   图片上传超出限制
            handleExceed () {
            },
            //   图片上传成功
            handleSuccess (res, file, fileList) {
                if (res && res.code === 0) {
                    this.headPath = res.path || ''
                    this.imageUrl = URL.createObjectURL(file.raw)
                    this.srcList.push(this.imageUrl)
                    this.sendText('IMAGE')
                } else {
                    this.$message.error(res.msg)
                }
            },
            //   关闭
            handleClose () {
                this.dialogVisible = false
                this.ws.close()
                clearInterval(this.messageTime)
                this.messageTime = null
                this.$emit('closeCallBack', true)
            },
            //   消息类型切换
            handleClick () {
            }
        }
    }
</script>

<style scoped>
    .chat-box /deep/ .el-dialog {
        height: 700px;
    }

    .chat-box /deep/ .el-dialog__header {
        padding: 0;
    }

    .chat-box /deep/ .el-dialog__body {
        height: 100%;
        padding: 0;
    }

    .chat-box /deep/ .el-tabs__item {
        text-align: center;
        width: 100%;
    }

    .chat-box /deep/ .el-tabs__header {
        margin-bottom: 8px;
    }

    .chat-box /deep/ .el-textarea__inner {
        border: none;
    }

    .chat-box /deep/ .el-image__preview {
        border-radius: 4px;
    }
    /*图片加载失败*/
    .chat-box /deep/ .el-image__error{
        height: 100px;
        width: 140px;
        border-radius: 4px;
    }
    .select-style {
        background-color: #fff;
    }

    .title-close {
        position: absolute;
        top: 8px;
        right: 10px;
        cursor: pointer;
        font-size: 18px;
    }

    /*    文本溢出隐藏*/
    .text-hiding {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    /*    用户聊天气泡*/
    .sys-text {
        position: relative;
        padding: 8px;
        font-size: 14px;
        background-color: #c0ccda;
        border: 1px solid #c0ccda;
        border-radius: 4px;
        max-width: 400px;
        margin-left: 12px;
        word-break: break-all;
        word-wrap: break-word;
    }

    .sys-text::before {
        content: '';
        position: absolute;
        /*width: 10px;*/
        /*height: 10px;*/
        left: -10px;
        top: 50%;
        transform: translateY(-10px);
        /*background-color: #c0ccda;*/
        /*border-radius: 40px 0 0 0;*/
        border-top: 5px solid transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid #c0ccda;
        border-bottom: 5px solid transparent;
    }

    /*    专家聊天气泡*/
    .expert-text {
        position: relative;
        padding: 8px;
        font-size: 14px;
        background-color: #3db94d;
        border: 1px solid #3db94d;
        color: #fff;
        border-radius: 4px;
        max-width: 400px;
        margin-right: 12px;
        word-break: break-all;
        word-wrap: break-word;
    }

    .expert-text:after {
        content: '';
        position: absolute;
        /*width: 10px;*/
        /*height: 10px;*/
        right: -10px;
        top: 50%;
        transform: translateY(-10px);
        /*background-color: #c0ccda;*/
        /*border-radius: 40px 0 0 0;*/
        border-top: 5px solid transparent;
        border-left: 5px solid #3db94d;
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
    }

    /*    辅助聊天气泡*/
    .auxiliary-text {
        padding: 5px 8px;
        background-color: rgba(0, 0, 0, .7);
        color: #fff;
        border-radius: 5px;
        text-align: center;
        font-size: 12px;
    }

    /*    聊天时间*/
    .chat-time {
        padding: 2px 5px;
        /*color: #fff;*/
        background-color: #eeeded;
        border-radius: 4px;
        text-align: center;
        font-size: 12px;
    }

    /*element滚动条*/
    .chat-box /deep/ .el-scrollbar__wrap {
        overflow-x: hidden !important;
    }
    .el-select-dropdown__wrap {
        max-height: 500px !important;
        min-height: 130px !important;
    }

    .doctor_header {
        height: 60px;
        width: 100%;
        border-bottom: 1px solid #eeeded;
        color: #fff;
        background-image: url("../../assets/img/doctorBlue.jpg");
        background-size: cover;
    }
</style>
